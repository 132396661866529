<template>
  <div>
      <el-card>
          <el-table
    :data="tableData"
    height="550"
    border
    style="width:100%;margin-top:50px;">
    <el-table-column
      prop="cpmc"
      label="客户名称">
    </el-table-column>
     <el-table-column
      prop="cpmc"
      label="产品名称">
    </el-table-column>
    <el-table-column
      prop="chandi"
      label="交易量"
      width="180">
    </el-table-column>
    
       <el-table-column
      prop="sym"
      label="交易金额">
    </el-table-column>
    <el-table-column
      prop="sym"
      label="交易合同">
    </el-table-column>
    <el-table-column
      prop="sym"
      label="日期">
    </el-table-column>
  </el-table>

<el-pagination
        style="text-align:center;margin-top:10px;"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        background
        layout="prev, pager, next, total"
        :total="total"
        :page-size="queryInfo.pageSize"
      >
      </el-pagination>
      </el-card>
  </div>
</template>

<script>
export default {
    data(){
        return{
            tableData:[],
            total:0,
            queryInfo:{
                page:1,
                pageSize:5
            }
        }
    }

}
</script>

<style>

</style>